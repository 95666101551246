import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { useChatScroll } from "../../../app/hooks/useChatScroll";
import Loading from "../../Loading";
import ChatItemReceiver from "./ChatItemReceiver";
import ChatItemSender from "./ChatItemSender";

const Wrapper = styled.div`
  display: flex;
  overflow-x: hidden;
  align-items: center;
  background-color: var(--b-b50);
  flex-direction: column;
  flex: 1;
  padding-bottom: 32px;

  @media only screen and (max-width: 480px) {
    padding-bottom: 20px;

  }
`;

const MessageList = styled.div`
  width: 100vw;
  height: 100%;
  flex: 1;
  overflow-y: auto;
  scroll-behavior: smooth; 
  align-items: center;
  justify-content: center;
  display: flex;

`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  max-width: var(--max-width);

`

const ChatMessageContainerSender = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

const ChatMessageContainerReceiver = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const ChatBody = React.forwardRef(({ chatMessages, loading, acceptBid, chatRef }) => {
  const dispatch = useDispatch();
  const bottomRef = useRef(null);
  const user = useSelector((state) => state.profile.profile);
  const [userId, setUserId] = useState("");

  useChatScroll({
    chatRef,
    bottomRef,
    shouldLoadMore: false,
    loadMore: () => console.log('load'),
    loading,
    count: chatMessages.length,
  });

  useEffect(() => {
      if (user?.data?.usertype === 'company') {
        setUserId(user?.data?.user?.company_id);
      } else {
        setUserId(user?.data?.id);
      }
    
  }, [user]);

  return (
    <Wrapper>
      {loading ? (
        <Loading />
      ) : (
        <MessageList ref={chatRef}>
          <Content>
            {chatMessages.map((message, index) => (
              <React.Fragment key={message.id}>
                {message?.user?.id !== userId ? (
                  <ChatMessageContainerSender>
                    <ChatItemReceiver
                      id={message.id}
                      currentMember={user}
                      member={message.user}
                      content={message.content}
                      fileUrl={message.file?.url}
                      timestamp={message.created}
                      message={message}
                      acceptBid={acceptBid}
                    />
                  </ChatMessageContainerSender>
                ) : (
                  <ChatMessageContainerReceiver>
                    <ChatItemSender
                      id={message.id}
                      currentMember={user}
                      member={message.user}
                      content={message.content}
                      fileUrl={message.file?.url}
                      timestamp={message.created}
                      message={message}
                      isLast={index === chatMessages.length - 1}
                    />
                  </ChatMessageContainerReceiver>
                )}
                {/*<ProposalCard />*/}
              </React.Fragment>

            ))}
            <div ref={bottomRef} />
          </Content>
        </MessageList>
      )}

    </Wrapper>
  );
});


export default ChatBody;
