import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Chatheader from "../../Components/Common/Chat/Chatheader";
import ChatInput from "../../Components/Common/Chat/ChatInput";
import { RiCoinsLine } from "react-icons/ri";
import PaperPlane from "../../Icons/PaperPlane";
import ChatFileUpload from "../../Icons/ChatFileUpload";
import FileUploadModal from "../../app/modals/FileUploadModal";
import useFileUploadModal from "../../app/hooks/useFileUploadModal";
import PitchOptionsModal from "../../app/modals/PitchOptionsModal";
import usePitchOptionsModal from "../../app/hooks/usePitchOptionsModal";
import apiClient from "../../services/api";
import ChatBody from "../../Components/Common/Chat/ChatBody";
import { usePusher } from "../../app/features/PusherContext";
import { find } from "lodash";
import { fetchProfile } from "../../app/features/profileSlice";
import SquareButton from "../../Components/Common/Chat/SquareButton";
import { Button } from "../../Components/UI";
import useDeletePitchModal from "../../app/hooks/useDeletePitch";
import DeletePitchModal from "../../app/modals/DeletePitchModal";
import usePitchStatus from "../../app/hooks/usePitchStatus";

const StyledContainer = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
  height: ${(props) => props.height || "auto"};

  @media (max-width: 1200px) {
    padding: 0 32px;
  }

  @media (max-width: 748px) {
    padding: 0 16px;
  }
`;
const ChatContainer = styled.section`
  height: 100svh;
  width: 100vw;
  background-color: var(--b-b50);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

const ChatFooter = styled.div`
  background-color: white;
  padding: 10px 0px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  width: 100%;
  z-index: 99;
`;

const ChatInputContainer = styled.form`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const MakeOfferContainer = styled.div`
  //flex: 1;
  display: flex;
  //justify-content: space-between;
  align-items: center;
  widht: 100%;
  height: 100%;
  gap: 8px;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Chatscreen = () => {
  const chatBodyRef = useRef(null);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const fileUploadModal = useFileUploadModal();
  const pitchOptionsModal = usePitchOptionsModal();
  const deletePitchModal = useDeletePitchModal();
  const { pitchStatus, setPitchStatus } = usePitchStatus();

  const [chatMessages, setChatMessages] = useState([]);
  const [pitch, setPitch] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const [marketplace, setMarketplace] = useState(null);
  const [conversationId, setConversationId] = useState(null);

  const user = useSelector((state) => state.profile.profile);
  const { marketplaceId, campaignId, pitchId } = params;

  const currentUrl = window.location.href;
  const isCampaign = currentUrl.includes("campaign");

  useEffect(() => {
    const conversationId = searchParams.get("conversationId");

    if (marketplaceId) {
      apiClient.get(`/marketplace/${marketplaceId}`).then((resp) => {
        const marketplaceItem = resp.data.data;
        setMarketplace(marketplaceItem);

        if (conversationId) {
          setConversationId(conversationId);
        } else {
          apiClient.get(`/chat/marketplace/${marketplaceId}`).then((resp) => {
            const { id } = resp.data.data;
            setConversationId(id);
          });
        }
      });
    } else {
      // Fetch campaignPitch
      apiClient.get(`campaign/${campaignId}/pitch/${pitchId}`).then((resp) => {
        const pitchData = resp.data.data;
        setPitch(pitchData);
        setPitchStatus(pitchData.status);

        if (conversationId) {
          setConversationId(conversationId);
        } else {
          const { conversation_id } = resp.data.data;
          setConversationId(conversation_id);
        }
      });
      // Fetch campaign
      apiClient.get(`campaign/${campaignId}`).then((resp) => {
        const campaignData = resp.data.data;
        setCampaign(campaignData);
      });
    }
  }, [params, marketplaceId, pitchId, campaignId, searchParams]);

  const pusher = usePusher();

  const fetchChatMessages = () => {
    if (conversationId) {
      apiClient.get(`chat/${conversationId}`).then((response) => {
        setChatMessages(response.data.data);

        if (loading) {
          setLoading(false);
        }
      });
    }
  };

  /**
   * If there is no userdata, get the userdata
   */
  useEffect(() => {
    if (!user?.data) {
      dispatch(fetchProfile());
    }
  }, [dispatch, user]);

  useEffect(() => {
    fetchChatMessages();

    if (pusher) {
      const channel = pusher.subscribe(`chat_${conversationId}`);

      channel.bind("chat-message", (chatData) => {
        // zorg ervoor dat er geen dubbele messages komen in chatmessages
        setChatMessages((current) => {
          if (find(current, { id: chatData.id })) {
            return current;
          }

          return [...current, chatData];
        });
      });

      return () => {
        pusher.unsubscribe(`chat_${conversationId}`);
        channel.unbind("chat-message");
      };
    } else {
      const intervalId = setInterval(fetchChatMessages, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [pusher, conversationId]);

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      message: "",
    },
  });

  const onSubmit = (message) => {
    setValue("message", "");

    if (conversationId) {
      let chatData = {
        content: message.message,
        conversation: conversationId,
      };
      if (file) {
        chatData = new FormData();
        chatData.set("file", file);
        chatData.append("content", message.message || "file");
        chatData.append("conversation", conversationId);
        setFile(null);
      }

      apiClient
        .post("/chat/send", chatData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          //TO DO: BIG IMAGES
        });

      fileUploadModal.onClose();
    }
  };

  const makeNewOffer = () => {
    navigate(`/propose/${campaign.id}/${pitch.id}`);
  };

  const acceptBid = async (bidId) => {
    apiClient.post(`pitch/${pitch.id}/${bidId}/accept `).then((response) => {
      setPitchStatus("accepted");

      setTimeout(() => {
        chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
      }, 500);
    });
  };

  const closePitch = () => {
    apiClient
      .get(`pitch/${pitch.id}/close`)
      .then((response) => {
        // Controleer of de API-aanroep succesvol was
        if (response.status === 200) {
          // Update de pitchStatus-staat naar 'decline'
          setPitchStatus("closed");
        } else {
          // Handel eventuele fouten af
          console.error("Fout bij het bijwerken van de pitchstatus.");
        }
      })
      .catch((error) => {
        // Handel eventuele fouten af
        console.error("Er is een fout opgetreden:", error);
      })
      .finally(() => {
        deletePitchModal.onClose();
      });
  };

  return (
    <ChatContainer>
      <Chatheader
        actionLabel={"Back"}
        headerTitle={
          isCampaign ? campaign?.title || "" : marketplace?.user?.name || " "
        }
      />
      <ChatBody
        chatMessages={chatMessages}
        loading={loading}
        acceptBid={acceptBid}
        chatRef={chatBodyRef}
      />
      {pitchStatus !== "closed" && (
        <ChatFooter>
          <StyledContainer>
            <ChatInputContainer onSubmit={handleSubmit(onSubmit)}>
              <ChatInput
                id="message"
                register={register}
                placeholder="Write a message..."
              />
              <SquareButton
                primary
                type="submit"
                Icon={<PaperPlane fill="white" />}
              />
            </ChatInputContainer>

            <BottomContainer>
              <MakeOfferContainer>
                <SquareButton
                  onClick={fileUploadModal.onOpen}
                  type="button"
                  Icon={<ChatFileUpload />}
                />
                <Right>
                  <Button
                    mt={"0px"}
                    text="Pitch options"
                    onClick={() => pitchOptionsModal.onOpen()}
                    disabled={!isCampaign}
                  />

                  <Button
                    mt={"0px"}
                    text="New offer"
                    Icon={<RiCoinsLine />}
                    onClick={makeNewOffer}
                    disabled={pitchStatus === "accepted" || !isCampaign}
                  />
                </Right>
              </MakeOfferContainer>
            </BottomContainer>
          </StyledContainer>
        </ChatFooter>
      )}
      <PitchOptionsModal />
      <FileUploadModal onfileUpload={setFile} />
      <DeletePitchModal onDelete={closePitch} />
    </ChatContainer>
  );
};

export default Chatscreen;
