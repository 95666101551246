import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import '../../Forms/multiselect.css'
import { ErrorMessage, HeadlineM, SubheadingS } from '../../../Components/Text';
import { FormContext } from '../../../Pages/ProfileSetup/FormProvider';
import { InputLabel, Bio } from '../../Forms';
import CustomSelect from '../../Common/Questions/CustomSelect';
import MultipleSelectCheckbox from '../../Common/Questions/MultiSelectCheckbox/MultipleSelectCheckbox';
import MultiSelectDropdown from '../../Common/Questions/MultiSelectDropdown';
import useMultiCheckboxHandler from '../../../app/hooks/useMultiCheckboxHandler';
import FileDropZone  from './FileDropZone';
import { handleCustomSelectChange, handleMultiSelectDropdownChange, handleInputChange, handleSubmit } from '../../../Pages/ProfileSetup/formUtils';
import { updateFormData } from '../../../Utils/utility-functions';
import { profileSetup } from '../../../app/features/profileSlice';
import { Button } from '../../../Components/UI';

export default function CompanyUserForm({ data, answers, customLabel}) {
    const formCtx = useContext(FormContext)
    const { handleMultiCheckboxChange, selectedOptionsDict } = useMultiCheckboxHandler(formCtx);
    const [image, setImage] = useState();
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch()
    const navigate = useNavigate();

    useEffect(() => {
        formCtx.createFormData(answers);
    }, [answers]);

    const clearError = (fieldName) => {
        if (errors[fieldName]) {
            const updatedErrors = { ...errors };
            delete updatedErrors[fieldName];
            setErrors(updatedErrors);
        }
    };

    const CustomSelectChange = (e, options) => {
        handleCustomSelectChange(e, options, formCtx, clearError);
    };

    const MultiSelectDropdownChange = (questionName, selected) => {
        handleMultiSelectDropdownChange(questionName, selected, formCtx, clearError);
    };

    const inputChange = (e, question) => {
        handleInputChange(e, question, formCtx, clearError);
    };

    const updateImage = (image) => {
        let formData = new FormData();

        if (image) {
            formData.set("logo", image);
        }
        return formData
    }
    
    const onSubmit = () => {

        const errors = handleSubmit(data, formCtx, setErrors);

        let completeData = formCtx.formData;

        if (errors) {
            setErrors(errors);

            const errorElement = document.getElementById(Object.keys(errors)[0]);

            if (errorElement) {
                errorElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                });
              }

            return;
        }

        if (image) {
            const formDataWithImage = updateImage(image);
            completeData = updateFormData(formCtx.formData, formDataWithImage);
        }

        dispatch(profileSetup({ data: completeData }))
            .then((action) => {
                if (profileSetup.fulfilled.match(action)) {
                    if (action.payload.success) {
                        if (action.payload.data.profile_filled) {
                            navigate('/');
                        }
                    }
                }
            });
    };

    const renderInputByFieldType = (fieldtype, question) => {

        switch (fieldtype) {
            case 'input':
                return (
                    <InputLabel
                        subheading={question.sublabel}
                        placeholder={question.placeholder}
                        onChange={(e) => inputChange(e, question)}
                        value={formCtx.formData[question?.name]}

                    />);
            case 'select':
                return (
                    <CustomSelect
                        question={question}
                        showLabel={false}
                        onChange={CustomSelectChange}
                        defaultValue={formCtx.formData[question?.name]}
                        customLabel={customLabel}
                    />
                )
            case 'checkbox':
                return (
                    <MultipleSelectCheckbox
                        question={question}
                        showLabel={false}
                        onChange={handleMultiCheckboxChange}
                        selectedOptionsDict={selectedOptionsDict} />
                )
            case 'textarea':
                return (
                    <Bio
                        onChange={inputChange}
                        question={question}
                        value={formCtx.formData[question?.name]}
                    />
                )
            case 'checkbox_select':
                return <MultiSelectDropdown question={question} onChange={MultiSelectDropdownChange} showLabel={false} />
            default:
                return null;
        }
    };

    return (
        <React.Fragment>
            {formCtx.formData && data && data.map((questionChunk, index) => (
                <div key={index}>
                    {index === 1 && (
                        <React.Fragment>
                            <SubheadingS>Upload company logo</SubheadingS>
                            <FileDropZone setImage={setImage} image={image} />
                        </React.Fragment>
                    )}
                    <HeadlineM>{questionChunk.heading}</HeadlineM>
                    {questionChunk.questions.map((question, questionIndex) => (
                        <div
                            key={questionIndex}
                            id={question.name}
                        >
                            <SubheadingS>{question.label}</SubheadingS>
                            {renderInputByFieldType(question.fieldtype, question)}
                            <ErrorMessage message={errors?.[question?.name]} />
                        </div>
                    ))}
                </div>
            ))}
            <Button onClick={onSubmit} fwidth={'100%'} text={'Submit'} mt={'24px'} primary />
            {Object.keys(errors)?.length > 0 && <ErrorMessage message={"fill in all mandatory fields"} />}
        </React.Fragment>
    )
}
