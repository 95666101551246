/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

/* -------------------------- Internal Dependencies ------------------------- */
import { HeadingXL, SubheadingS, ParagraphM } from "../../Components/Text";
import { LayoutCenterTop } from "../../Components/Layout";
import { Button, RoundButtonLink } from "../../Components/UI";
import {  FieldErrorMessage } from "../../Components/Forms";
import { PhoneBox } from "../../Components/Common";

/* -------------------------- Internal SVG Dependencies ------------------------- */
import Logo from "../../Icons/Logo";
import { profileSetup } from "../../app/features/profileSlice";
import { useDataSource } from "../../Utils/hooks/useDataSource";
import NationalitySelect from "./NationalitySelect";
import FileDropZone from "./FileDropZone";
import Close from "../../Icons/Close";

const UserInfo = () => {
    const userType = localStorage.getItem('usertype')
    const user = useSelector((state) => state.profile.profile);

    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneError, setPhoneError] = useState(false);
    const [nationalityError, setNationalityError] = useState(false);
    const [nationality, setNationality] = useState("");
    const [image, setImage] = useState();
    const [callbackImage, setCallbackImage] = useState();
    const { resource: countries } = useDataSource('nationalities');
    const { resource: profile } = useDataSource('/me');
    const { resource: questions, } = useDataSource('/questions?type=profile')
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (profile) {
            const { data } = profile;

            if (data?.avatar) {
                // setImage(data.avatar);
                setCallbackImage(data.avatar)
            }

            if(data?.profile?.phonenumber || data?.phonenumber){
                setPhoneNumber(data?.profile?.phonenumber || data?.phonenumber );
            }
            // console.log('Data in userinfo:', data)

        }
    }, [profile])
    

    const handlePhoneChange = (value) => {
        setPhoneNumber(value);
    };

    const onChangeSelect = (selectedId) => {
        //const selectedId = e.target.value;
        const selectedObject = countries.find(item => item.id === selectedId);
        setNationality(selectedObject);
    }

    const updateImage = (image) => {
        let formData = new FormData();

        if (image) {
            formData.set("image", image);
        }
        formData.append("phonenumber", "+" + phoneNumber);
        formData.append("nationality", nationality.id);

        return formData

    }

    const navigateBasedOnUserType = (userType) => {
        userType === 'influencer' ?
            navigate('/setupprofilecreative') :
            navigate('/setupprofilecompany', {
                state: {
                    questions,
                    profile
                }
            });
    }

    const handleFormSubmit = () => {

        if (!nationality) {
            setNationalityError(true)
            return;
        }

        if (phoneNumber.length < 8) {
            setPhoneError(true)
            return;
        }

        localStorage.setItem("country", nationality.name);

        const formData = updateImage(image);

        if (image) {
            formData.set("image", image);
        } else if (profile && profile.data && profile.data.avatar) {
            formData.image = callbackImage
        } else {
            console.log("No image provided");
        }

        formData.nationality = nationality.name;
        formData.phoneNumber = phoneNumber;

        // console.log('Formdata', formData)

        dispatch(profileSetup({ data: formData }))
            .then((action) => {
                if (profileSetup.fulfilled.match(action)) {
                    navigateBasedOnUserType(userType)
                }
            });
    
        navigateBasedOnUserType(userType)

    };
    

    return (
        <LayoutCenterTop>
            <Container>
                <Logo height={35} />
                <div onClick={()=> navigate('/me')}>
                    <RoundButtonLink
                        height={"40px"}
                        width={"40px"}
                        fill={"#FFF"}
                        onClick={()=>navigate("/me")}
                        Icon={<Close fill={"#364E5D"} />}
                    />
                </div>
            </Container>
            {profile && <HeadingXL>Hi {profile?.data?.firstname || profile?.data?.user?.firstname}</HeadingXL>}
            <ParagraphM>
                Please fill out all the information below so we can setup your account on myThursd.
            </ParagraphM>
            <SubheadingS>Upload a personal picture.</SubheadingS>
            <ParagraphM colorSecondary>
                Please add a personal picture. myThursd is all about personal contact between you and advertisers.
            </ParagraphM>
            <FileDropZone setImage={setImage} image={image} avatarImage={image}/>
            {countries && profile &&
                /*<CustomSelect
                    label={"Nationality"}
                    customLabel="Select your nationality"
                    question={countries}
                    labelField={"nationality"}
                    onChange={onChangeWithLabelField}
                    defaultValue={profile?.data?.nationality || null}
            />*/

                <NationalitySelect
                    label={"Nationality"}
                    data={countries}
                    onChange={onChangeSelect}
                    defaultValue={profile?.data?.nationality || null}

                />
            }

            {nationalityError &&
                <div style={{ marginTop: 8 }}>
                    <FieldErrorMessage text={"This field is mandatory. Please select a nationality"} />
                </div>
            }

            <SubheadingS>Phone number </SubheadingS>
            <ParagraphM colorSecondary>
                We need your phone number. We will only call you for urgent matters
                concerning projects by advertisers you are working on.
            </ParagraphM>
            <PhoneBox>
                <PhoneInput
                    autoFormat
                    value={phoneNumber}
                    onChange={handlePhoneChange}                    
                    country="nl"
                    inputStyle={{
                        border: "none",
                        transform: "translateY(-6px)",
                        backgroundColor: "transparent",
                        color: "#727272",
                        marginLeft: "-8px",
                        "&:placeholder": {
                            color: "#727272",
                        },
                    }}
                    placeholder={"(0)6-12345678"}
                    dropdownStyle={{ border: "none" }}
                    buttonStyle={{
                        border: "none",
                        transform: "translateY(-6px)",
                        background: "none",
                    }}
                />
            </PhoneBox>
            {phoneError &&
                <div style={{ marginTop: 8 }}>
                    <FieldErrorMessage text={"This field is mandatory. Please type in a valid phonenumber"} />
                </div>
            }
            {" "}
            <Button
                fwidth
                primary={"true"}
                text={user.data.onboarded === true ? "Update Profile" : "Create Account" }
                onClick={() => handleFormSubmit()}
            />
        </LayoutCenterTop>
    );
};

export default UserInfo;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
`
