import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { profileSetup } from "../app/features/profileSlice";
import { FaPen } from "react-icons/fa";

const AvatarBox = styled.div`
    position: relative;
	display: flex;
	align-items: center;
	overflow: hidden;
    cursor: pointer;
    border-radius: 500px;
    border: ${(p) => (p.size ? "1px solid var(--b-b100)" : "2px solid var(--b-b100)")};
    height:${(p) => (p.size ? p.size : "80px")};
    width:${(p) => (p.size ? p.size : "80px")};
	background-color: var(--b-b50);
`;

const DropZoneContainer = styled.div`
    height:${(p) => (p.size ? p.size : "80px")};
    width:${(p) => (p.size ? p.size : "80px")};
`
const StyledImage = styled.img`
	height: 100%;
	width: 100%;
	object-fit: cover;
	margin-bottom: 10px;
`;

const Overlay = styled.div`
    position: absolute; 
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: transform 0.5s ease-in-out;

    &:hover{
        opacity: 100%;
    }
`

const PenContainer = styled.div`
position: absolute; 
bottom: ${(p) => (p.logo ? '4px' : "8px")};
right: ${(p) => (p.logo ? '0px' : "10px")};
opacity: 0;
aspect-ratio: 1/1;
width: ${(p) => (p.logo ? '16px' : "20px")};

${AvatarBox}:hover &{
    opacity: 1;
  }
`
const Avatar = ({ userImage, size, logo }) => {
    const [image, setImage] = useState();
    const dispatch = useDispatch()

    useEffect(() => {
        if (userImage) {
            setImage(userImage)
        }

    }, [userImage])

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        setImage(URL.createObjectURL(file));

        let formData = new FormData();

        if (logo) {
            formData.set("logo", file);
        } else {
            formData.set("image", file);

        }

        dispatch(profileSetup({ data: formData }))
            .then((action) => {
                if (profileSetup.fulfilled.match(action)) {
                }
            });
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: "image/*", // Allow only image files
    });
    return (
        <div style={{ width: size }}>
            <AvatarBox size={size}>
                <DropZoneContainer {...getRootProps()} size={size}>
                    <input {...getInputProps()} />
                    {image && (
                        <StyledImage src={image} alt="Avatar" />
                    )}
                    <Overlay />
                    <PenContainer logo={logo}>
                        <FaPen color={'#fff'} size={logo? 10 : 14} />
                    </PenContainer>
                </DropZoneContainer>
            </AvatarBox>
        </div>
    );
}

export default Avatar;