import React, { useEffect, useContext, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import '../../Forms/multiselect.css'
import { ErrorMessage, HeadlineM, SubheadingS } from '../../Text';
import { FormContext } from '../../../Pages/ProfileSetup/FormProvider';
import { InputLabel, Bio } from '../../Forms';
import CustomSelect from '../../Common/Questions/CustomSelect';
import MultipleSelectCheckbox from '../../Common/Questions/MultiSelectCheckbox/MultipleSelectCheckbox';
import MultiSelectDropdown from '../../Common/Questions/MultiSelectDropdown';
import useMultiCheckboxHandler from '../../../app/hooks/useMultiCheckboxHandler';
import { handleCustomSelectChange, handleMultiSelectDropdownChange, handleInputChange, handleSubmit } from '../../../Pages/ProfileSetup/formUtils';
import { profileSetup } from '../../../app/features/profileSlice';
import { Button } from '../../UI';

export default function InfluencerUserForm({ data, answers }) {
  const formCtx = useContext(FormContext)
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch()
  const navigate = useNavigate();

  useEffect(() => {
    //set initial data
    formCtx.createFormData(answers)
  }, [])

  useEffect(()=>{
  },[formCtx.formData])

  const clearError = (fieldName) => {
    if (errors[fieldName]) {
      const updatedErrors = { ...errors };
      delete updatedErrors[fieldName];
      setErrors(updatedErrors);
    }
  };

  const { handleMultiCheckboxChange } = useMultiCheckboxHandler(
    formCtx,
    clearError,
  );

  const CustomSelectChange = (e, options) => {
    handleCustomSelectChange(e, options, formCtx, clearError);
  };

  const MultiSelectDropdownChange = (questionName, selected) => {
    handleMultiSelectDropdownChange(questionName, selected, formCtx, clearError);
  };

  const inputChange = (e, question) => {
    handleInputChange(e, question, formCtx, clearError);
  };

  const onSubmit = () => {
    const errors = handleSubmit(data, formCtx, setErrors);

    let completeData = formCtx.formData;

    console.log('Complete data', completeData)

    if(errors){
      setErrors(errors);

      const errorElement = document.getElementById(Object.keys(errors)[0]);

      if (errorElement) {
          errorElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }

      return
    }

    dispatch(profileSetup({ data: completeData }))
      .then((action) => {
        if (profileSetup.fulfilled.match(action)) {
          if (action.payload.success) {
            if (action.payload.data.profile_filled) {
              navigate('/')
            }
          }
        }
      });

  }

  const renderInputByFieldType = (fieldtype, question) => {
    switch (fieldtype) {
      case 'input':
        return (
          <InputLabel
            subheading={question.sublabel}
            placeholder={question.placeholder}
            onChange={(e) => inputChange(e, question)}
            value={formCtx.formData[question?.name]}
          />);
      case 'select':
        return (
          <CustomSelect
            question={question}
            showLabel={false}
            onChange={CustomSelectChange}
            defaultValue={formCtx.formData[question?.name]}
          />
        )
      case 'checkbox':
        return (
          <MultipleSelectCheckbox
            question={question}
            showLabel={false}
            onChange={handleMultiCheckboxChange}
            selectedValues={formCtx.formData} 
          />
        )
      case 'textarea':
        return (
          <Bio
            onChange={inputChange}
            question={question}
            value={formCtx.formData[question?.name]}
          />
        )
      case 'checkbox_select':
        return <MultiSelectDropdown
          question={question}
          onChange={MultiSelectDropdownChange}
          showLabel={false}
          defaultValues={formCtx.formData[question?.name]}
        />
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      {formCtx.formData && data && data.map((questionChunk, index) => (
        <div key={index} >
          <HeadlineM>{questionChunk.heading}</HeadlineM>
          {questionChunk.questions.map((question, questionIndex) => (
            <div key={questionIndex} id={question.name}>
              <SubheadingS>{question.label}</SubheadingS>
              {renderInputByFieldType(question.fieldtype, question)}
              <ErrorMessage message={errors?.[question?.name]} />
            </div>
          ))}
        </div>
      ))}

      <Button onClick={onSubmit} fwidth={'100%'} text={'Submit'} mt={'24px'} primary />
      {Object.keys(errors)?.length > 0 && <ErrorMessage message={"fill in all mandatory fields"} />}
    </React.Fragment>
  )
}
