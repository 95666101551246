import useFileUploadModal from "../hooks/useFileUploadModal";
import Modal from "../modals/Modal";
import { FileDropZone } from "../../Components/Common";
import { SubheadingS } from "../../Components/Text";

const FileUploadModal = ({ onfileUpload }) => {
    const fileUploadModal = useFileUploadModal();

    const bodyContent = (
        <div style={{ padding: '0px 16px' }}>
            <SubheadingS mt={'16px'}>Upload a file to this chat</SubheadingS>
            <FileDropZone setImage={onfileUpload} fileUpload/>
        </div>
    )

    return (
        <Modal
            title={"Options for “Wedding in Monaco”"}
            isOpen={fileUploadModal.isOpen}
            onClose={fileUploadModal.onClose}
            body={bodyContent}
        />);
}

export default FileUploadModal;