/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import styled from "styled-components";
import { Document, Page, pdfjs } from "react-pdf";
/* -------------------------- Internal Dependencies ------------------------- */
import { theme } from "../../../Theme/theme";
import { ParagraphS } from "../../../Components/Text";
import RoundButtonLink from "../../UI/Buttons/RoundButtonLink";
/* -------------------------- Internal SVG Dependencies ------------------------- */
import DropzoneIcon from "../../../Icons/DropzoneIcon";
import CloseIcon from "../../../Icons/Close";

const StyledDropZoneWrapper = styled.div`
	margin-top: 10px;
	cursor: pointer;
`;

const StyledContainer = styled.div`
	height: 152px;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	border: 1px dashed var(--g-s200);
	background-color: var(--b-b50);
	color: var(--b-b400);
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 140%;
	cursor: pointer;
	display: ${(p) => p.avatarImage && "hidden"};

	span {
		color: ${(p) => p.color};
		text-decoration: underline;
	}
`;

const DroppedImageContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	aspect-ratio: 16/9;
`;

const DropZoneContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 16px;
	align-items: center;
`;

const FileContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 8px;
	&:hover {
		cursor: pointer;
	}
`;

const StyledListBox = styled.ul`
	list-style-type: disc;
	color: #a4afb6;
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 140%;
	margin-left: -12px;
`;

const StyledImage = styled.img`
	height: 100%;
	width: 100%;
	// object-fit: cover;
`;

const AvatarContainer = styled.div`
  width: 268px;
`

const FileDropZone = ({ image, setImage, avatarImage, onSubmit, fileUpload }) => {
	const [file, setFile] = useState(null);
	const [numPages, setNumPages] = useState(null);
	const [pageNumber] = useState(1);

	pdfjs.GlobalWorkerOptions.workerSrc =
		`//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	const handleDrop = (acceptedFiles) => {

		if (fileUpload && acceptedFiles[0].type === "application/pdf") {
			setFile(acceptedFiles[0]);
		} else if (acceptedFiles[0].type.startsWith("image/")) {
			// Check if fileUpload is false and the uploaded file is an image
			setFile(acceptedFiles[0]);
		}
	};

	const handleDelete = () => {
		setFile(null);
		setImage(undefined)
	};

	const onDocumentSucces = ({ numPages }) => {
		setNumPages(numPages)
	}

	React.useEffect(() => {
		if (file) {
			setImage(file);
		}
	}, [file]);

	return (
		<StyledDropZoneWrapper>
			<Dropzone autoProcessQueue onDrop={handleDrop} accept={fileUpload ? "application/pdf,image/*" : "image/*"} maxFiles={1}>
				{({ getRootProps, getInputProps }) => (
					<StyledContainer avatarImage={avatarImage} {...getRootProps()} file={file} color={theme.main}>
						<input {...getInputProps()} />
						{file ? (
							<DroppedImageContainer>
								{file.type.startsWith("image/") ? (
									<AvatarContainer>
                    <StyledImage
                      className="loaded"
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                    />									
                  </AvatarContainer>
								) : (
									<Document file={file} onLoadSuccess={onDocumentSucces}>
										<div style={{ width: '200px', height: '100px', position: 'relative' }}>
											<Page
												pageNumber={pageNumber}
												width={200}
												height={100}
											/>
										</div>
									</Document>

								)}
							</DroppedImageContainer>
						) : (
							<DropZoneContainer>
								<DropzoneIcon />
								<p>Drag & drop file here or <span>browse</span></p>
							</DropZoneContainer>
						)}
					</StyledContainer>
				)}
			</Dropzone>
			{file && (
				<FileContainer>
					<ParagraphS mt={"0px"}>{file.name}</ParagraphS>
					<div onClick={handleDelete}>
						<RoundButtonLink height={"20px"} width={"20px"} Icon={<CloseIcon height={"8px"} />} />
					</div>
				</FileContainer>
			)}
			<StyledListBox>
				<li>png, gif, jpeg, jpg, webp, svg {fileUpload ? ", pdf" : ""}</li>
				<li>Size limit: 5MB.</li>
				<li>We recommend a ratio of 1:1</li>
			</StyledListBox>
		</StyledDropZoneWrapper>
	);
};

export default FileDropZone;
