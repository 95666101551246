/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import styled from "styled-components";

/* -------------------------- Internal Dependencies ------------------------- */
import { ParagraphS } from "../../Components/Text";
import { RoundButtonLink } from "../../Components/UI";
import { theme } from "../../Theme/theme";

/* -------------------------- Internal SVG Dependencies ------------------------- */
import CloseIcon from "../../Icons/Close";
import DropzoneIcon from "../../Icons/DropzoneIcon";

const StyledDropZoneWrapper = styled.div`
  margin-top: 10px;
  cursor: pointer;
`;

const StyledContainer = styled.div`
  height: 152px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 1px dashed var(--g-s200);
  background-color: var(--b-b50);
  color: var(--b-b400);
  font-family: "Avenir LT Std";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  cursor: pointer;
  display: ${(p) => p.avatarImage && "hidden"};

  span {
    color: ${(p) => p.color};
    text-decoration: underline;
  }
`;

const DroppedImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  aspect-ratio: 16/9;
`;

const DropZoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  align-items: center;
`;

const FileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  &:hover {
    cursor: pointer;
  }
`;

const StyledListBox = styled.ul`
  list-style-type: disc;
  color: #a4afb6;
  font-family: "Avenir LT Std";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  margin-left: -12px;
`;

const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  // object-fit: cover;
`;

const AvatarContainer = styled.div`
  width: 268px;
`;

const FileDropZone = ({ image, setImage }) => {
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (image) {
      setFile(image);
    }
  }, [image]);

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles[0].type.startsWith("image/")) {
      setFile(acceptedFiles[0]);
    }
  };

  const handleDelete = () => {
    setFile(null);
    setImage(undefined);
  };

  useEffect(() => {
    if (file && !file.isUrl) {
      setImage(file);
    }
  }, [file, setImage]);

  return (
    <StyledDropZoneWrapper>
      <Dropzone onDrop={handleDrop} accept="image/*" maxFiles={1}>
        {({ getRootProps, getInputProps }) => (
          <StyledContainer
            avatarImage={!!file}
            {...getRootProps()}
            color={theme.main}
          >
            <input {...getInputProps()} />
            {file ? (
              <DroppedImageContainer>
                <AvatarContainer>
                  <StyledImage
                    className="loaded"
                    src={
                      typeof image === "string"
                        ? file
                        : URL.createObjectURL(file)
                    }
                    alt={file.name}
                  />
                </AvatarContainer>
              </DroppedImageContainer>
            ) : (
              <DropZoneContainer>
                <DropzoneIcon />
                <p>
                  Drag & drop file here or <span>browse</span>
                </p>
              </DropZoneContainer>
            )}
          </StyledContainer>
        )}
      </Dropzone>
      {file && (
        <FileContainer>
          <ParagraphS mt={"0px"}>{file.name}</ParagraphS>
          <div onClick={handleDelete}>
            <RoundButtonLink
              height={"20px"}
              width={"20px"}
              Icon={<CloseIcon height={"8px"} />}
            />
          </div>
        </FileContainer>
      )}
      <StyledListBox>
        <li>png, gif, jpeg, jpg, webp, svg</li>
        <li>Size limit: 5MB.</li>
        <li>We recommend a ratio of 1:1</li>
      </StyledListBox>
    </StyledDropZoneWrapper>
  );
};

export default FileDropZone;
