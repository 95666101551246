
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import ProfileInformationItem from "../../Components/Common/ProfilePage/ProfileInformationItem";
import { HeadlineM, HeadlineL, ParagraphM, SubheadingXS, ParagraphS } from "../../Components/Text";
import NavigationBar from "../../Components/Common/Navbar/NavigationBar";
import PenIcon from "../../Icons/PenIcon";
import Avatar from "../../Components/Avatar";
import { findNamesByKeys } from "./utils";
import { VscGlobe } from "react-icons/vsc";
import FlagBig from "../../Icons/FlagBig";
import CalenderIcon from "../../Icons/CalenderIcon";
import { useDataSource } from "../../Utils/hooks/useDataSource";
import Biography from "../../Components/Common/ProfilePage/Biography";
import { EndIconButton } from "../../Components/UI";

const Container = styled.div`
    min-height: 100vh; 
    background-color: rgba(54, 78, 93, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    @media (max-width: 780px) {
        align-items: flex-start;
        background-color: var(--g-s50);
    }
    `;

const NavigationContainer = styled.div`
    position: absolute;
    width: 100%;
    top: 0;
`

const ProfileContainer = styled.div`
    width: 90vw;
    max-width: 980px;
    border-radius: 8px;
    margin-top: 5rem;
    margin-bottom: 8rem;
    
    @media (max-width: 780px) {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background-color: rgba(54, 78, 93, 1);
        border-radius: 0px;
        margin-top: 68px; // headerheight
    }
`
const EditContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 26px 24px;
    box-sizing: border-box;
    max-width: 1000px;
    height: 73px;
    background-color: var(--g-s50);
    border-radius: 8px;
    align-items: center;
    margin-bottom: 24px;


    @media (max-width: 780px) {
        flex-direction: column;
        gap: 16px;
        max-width: 100%;
        width: 100%;
        height: 120px;
        justify-content: center;
        padding: 16px 24px;
        margin-bottom: 0;
        border-radius: 0px;
        background-color: white;
        position: fixed;
        bottom: 0px;
        z-index: 4;
    }

`
const ProfileHeaderInformation = styled.div`
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: rgba(54, 78, 93, 1);
    
`

const AboutSection = styled.div`
    width: 100%;
    height: auto;
    background-color: var(--g-s50);
    border-radius: 8px;

    @media (max-width: 780px) {
        border-radius: 0px;
    }

`

const TextContainer = styled.div`
    padding: 32px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 780px) {
        flex-direction: column;
    }
`

const SubContainer = styled.div`
    width: 100%;

    @media (max-width: 780px) {
        width: 100%;
    }
   
`
const SubTextBlock = styled.div`
   

    @media (max-width: 780px) {
        max-width: 100%;
    }
`

const ButtonContainer = styled.div`
    display: flex;
    gap: 8px;

     @media (max-width: 780px) {
        width: 100%;
    
    }
`

const ProfilePageCompany = ({ user }) => {
    const [myCountry, setMyCountry] = useState()
    const countriesData = useSelector((state) => state.countries?.countries);
    const navigate = useNavigate();
    const { resource: profile } = useDataSource('/me');
    const { resource: questions, } = useDataSource('/questions?type=profile')

    useEffect(() => {
        if (user) {
            setMyCountry(findNamesByKeys(user?.profile?.country, countriesData))
        }

    }, [countriesData, user])

    const logout = () => {
        localStorage.removeItem('token');
        setTimeout(() => {
            navigate('/');
            window.location.reload();
        }, 500);
    };

    return (
        <Container>
            <NavigationContainer>
                <NavigationBar />
            </NavigationContainer>
            <ProfileContainer>
                <EditContainer>
                    <ParagraphS>You are viewing your own profile</ParagraphS>
                    <ButtonContainer>
                        <EndIconButton
                            primary={true}
                            textcolor={true}
                            color={"var(--bb500)"}
                            text={"Edit profile"}
                            mt={"0px"}
                            onClick={() => navigate('/userinfo', {
                                state: {
                                    questions,
                                    profile
                                }
                            })}
                        >
                            <PenIcon />
                        </EndIconButton>
                        <EndIconButton
                            primary={true}
                            textcolor={true}
                            color={"var(--bb500)"}
                            text={"Log out"}
                            mt={"0px"}
                            onClick={logout}
                        >
                        </EndIconButton>
                    </ButtonContainer>
                </EditContainer>
                <ProfileHeaderInformation>
                    <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'flex-start', gap: 16 }}>
                        <Avatar userImage={user?.avatar} />
                        <div>
                            <HeadlineL mt={'0px'} color={"white"}>{user && user?.profile?.company_name}</HeadlineL>
                            <div style={{ display: 'flex', gap: 8, marginTop: '8px' }}>
                                <Avatar userImage={user?.logo} size={"32px"} logo />
                                <ParagraphM color="white">{user?.user?.firstname} {user?.user?.lastname}</ParagraphM>
                            </div>
                        </div>
                    </div>
                    <div style={user ? { padding: '16px 0', display: 'flex', flexDirection: 'column', gap: '8px' } : { padding: '0px' }}>
                    {user &&
                            <ProfileInformationItem
                                title={'Website'}
                                answer={user?.profile?.website}
                                Icon={<VscGlobe size={20}/>}
                                link={true}
                            />}
                        {myCountry &&
                            <ProfileInformationItem
                                title={'Active in'}
                                answer={myCountry || 'Global'}
                                Icon={<FlagBig />}
                            />}
                        {user && <ProfileInformationItem
                            title={'Account created'}
                            answer={user?.user?.registered}
                            Icon={<CalenderIcon />}
                        />
                        }
                    </div>
                </ProfileHeaderInformation>
                <AboutSection>
                    <TextContainer>
                        <SubContainer>
                            <HeadlineM mt={'0px'}>About the advertiser</HeadlineM>
                            <SubTextBlock>
                                <SubheadingXS mt={'16px'}>Mission</SubheadingXS>
                                <ParagraphM>{user?.profile?.mission}</ParagraphM>
                            </SubTextBlock>

                            <SubTextBlock>
                                <SubheadingXS mt={'16px'}>Vision</SubheadingXS>
                                <ParagraphM>{user?.profile?.vision}</ParagraphM>
                            </SubTextBlock>
                            <div>
                                {user && <Biography text={user?.profile?.biography} />}
                            </div>
                        </SubContainer>
                    </TextContainer>
                </AboutSection>
            </ProfileContainer>
        </Container>
    );
}

export default ProfilePageCompany;