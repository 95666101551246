import React from 'react'
import { useLocation } from "react-router-dom";
import { LayoutCenterTop } from '../../../Components/Layout'
import { HeadingXL, ParagraphM } from '../../../Components/Text'
import { useDataSource } from '../../../Utils/hooks/useDataSource'
import CompanyUserForm from './CompanyUserForm'
import FormProvider from '../../../Pages/ProfileSetup/FormProvider';

export default function SetupProfileCompany() {
    const { resource: questions } = useDataSource('/questions?type=profile')
    const { resource: answers } = useDataSource('/me')
    const { state } = useLocation()
    const profileData = questions?.data;
    return (
        <FormProvider>
            <LayoutCenterTop>
                <HeadingXL>Tell us about your company</HeadingXL>
                <ParagraphM>Please fill in the information below so we can finalize your registration.</ParagraphM>
                {
                    state?.questions?.data && state.profile?.data?.profile
                        ? <CompanyUserForm data={state?.questions?.data} answers={state.profile?.data?.profile} customLabel="Select your country " />
                        : <CompanyUserForm data={profileData} answers={answers?.data?.profile} customLabel="Select your country " />
                }
            </LayoutCenterTop>
        </FormProvider>
    )
}
